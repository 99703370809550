import React from "react";
import loadable from "@loadable/component";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const Frame = loadable(() => import("@templates/Frame"));

const PagesDefault = ({ data, ...props }) => {
  const { title, pageBuilder } = data;
  return (
    <DefaultPageContainer {...props}>
      <Frame title={title} />
      <article className="pb-32 pt-14 text-white">
        <PageBuilder blocks={pageBuilder} />
      </article>
    </DefaultPageContainer>
  );
};

export default PagesDefault;
