import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/PagesDefault";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PagesDefaultQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "pages", uri: $uri, siteId: $siteId) {
        ... on Craft_pages_default_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          pageBuilder {
            ... on Craft_pageBuilder_hero_BlockType {
              uid
              type: typeHandle
              heading
              subheading
            }
            ... on Craft_pageBuilder_stats_BlockType {
              uid
              type: typeHandle
              stat
              label
            }
            ... on Craft_pageBuilder_imageCopy_BlockType {
              uid
              type: typeHandle
              image {
                ...ImageFragment
              }
              copy
            }
            ... on Craft_pageBuilder_leadCopy_BlockType {
              uid
              type: typeHandle
              copy
            }
            ... on Craft_pageBuilder_image_BlockType {
              uid
              type: typeHandle
              image {
                ...ImageFragment
              }
            }
            ... on Craft_pageBuilder_imageGrid_BlockType {
              uid
              type: typeHandle
              captionOverride
              images {
                ...ImageFragment
              }
            }
            ... on Craft_pageBuilder_copyButtons_BlockType {
              uid
              type: typeHandle
              copy
              heading
              buttons {
                ... on Craft_buttons_BlockType {
                  button {
                    url
                    text
                  }
                }
              }
            }
            ... on Craft_pageBuilder_list_BlockType {
              uid
              type: typeHandle
              heading
              listItems {
                item
              }
              columns
            }
            ... on Craft_pageBuilder_video_BlockType {
              uid
              type: typeHandle
              videoUrl
            }
            ... on Craft_pageBuilder_financialTable_BlockType {
              uid
              type: typeHandle
              tables {
                ... on Craft_tables_BlockType {
                  table {
                    ... on Craft_table_TableRow {
                      heading
                      currentYear
                      previousYear
                      group
                      color
                    }
                  }
                }
              }
              disclaimer
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const { pageBuilder, title } = entry;
  return {
    ...entry,
    meta: {
      title,
    },
    pageBuilder: resolvePageBuilder(pageBuilder),
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PagesDefaultQuery"
    {...props}
  />
);

export default Page;
